@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;500;600&display=swap");
* {
  font-family: "Poppins", sans-serif;
}

.navbarContainer {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  background-color: white;
  height: 10vh;
  padding: 10px 20px;
}

.navbarContainer button {
  width: 10%;
  -ms-flex-item-align: center;
      -ms-grid-row-align: center;
      align-self: center;
  border-radius: 50px;
  padding: 10px 20px;
  border: 1px solid #ffffff;
  color: #ffffff;
  cursor: pointer;
  outline: none;
  background: -webkit-gradient(linear, left top, left bottom, from(#b2f1ff), to(rgba(252, 153, 255, 0.86)));
  background: linear-gradient(180deg, #b2f1ff 0%, rgba(252, 153, 255, 0.86) 100%);
}

.navbarContainer a {
  width: 100%;
  text-decoration: none;
  color: white;
}

@media screen and (max-width: 800px) {
  .navbarContainer button {
    width: 25%;
    -ms-flex-item-align: center;
        -ms-grid-row-align: center;
        align-self: center;
    border-radius: 50px;
    padding: 10px 20px;
    border: 1px solid #ffffff;
    color: #ffffff;
    cursor: pointer;
    outline: none;
  }
  .navbarContainer img {
    width: 100px;
    height: 40px;
    -ms-flex-item-align: center;
        -ms-grid-row-align: center;
        align-self: center;
  }
  .navbarContainer .logo {
    width: 60px;
    height: 50px;
  }
}
