@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;500;600&display=swap");

$white: #ffffff;
* {
  font-family: "Poppins", sans-serif;
}

@mixin gradientButton($width) {
    width:$width;
    align-self: center;
    border-radius: 50px;
    padding: 10px 20px;
    border: 1px solid $white;
    color:$white;
    cursor: pointer;
    outline: none;
}
