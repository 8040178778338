@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;500;600&display=swap");
* {
  font-family: "Poppins", sans-serif;
}

.headerContainer {
  height: 100vh;
}

.headerContainer .mainContainer {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
}

.headerContainer .mainContainer .leftSide {
  width: 75vw;
  height: 90vh;
}

.headerContainer .mainContainer .leftSide img {
  -o-object-fit: cover;
     object-fit: cover;
  width: 100%;
  height: 100%;
}

.headerContainer .mainContainer .rightSide {
  background-color: black;
  position: absolute;
  right: 0;
  background: -webkit-gradient(linear, left top, left bottom, from(#9dd0f4), to(#ccb2f8));
  background: linear-gradient(180deg, #9dd0f4 0%, #ccb2f8 100%);
  padding: 5%;
  height: 90vh;
  width: 40vw;
  color: #ffffff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}

.headerContainer .mainContainer .rightSide h1 {
  font-weight: 600;
  font-size: 40px;
  width: 100%;
}

.headerContainer .mainContainer .rightSide p {
  width: 100%;
  font-size: 25px;
}

.headerContainer .mainContainer .rightSide .opacityp {
  opacity: 0.8;
  color: #5485b1;
}

.headerContainer .mainContainer .rightSide button {
  width: 50%;
  -ms-flex-item-align: center;
      -ms-grid-row-align: center;
      align-self: center;
  border-radius: 50px;
  padding: 10px 20px;
  border: 1px solid #ffffff;
  color: #ffffff;
  cursor: pointer;
  outline: none;
  background: -webkit-gradient(linear, left top, left bottom, from(#7fdafe), to(rgba(89, 138, 233, 0.86)));
  background: linear-gradient(180deg, #7fdafe 0%, rgba(89, 138, 233, 0.86) 100%);
}

@media screen and (max-width: 1200px) {
  .headerContainer .mainContainer .leftSide {
    width: 95vw;
    height: 90vh;
  }
  .headerContainer .mainContainer .rightSide h1 {
    font-size: 30px;
  }
}

@media screen and (max-width: 800px) {
  .headerContainer .mainContainer {
    background-image: url("../assets/mobile.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
  }
  .headerContainer .mainContainer .leftSide img {
    display: none;
  }
  .headerContainer .mainContainer .rightSide {
    background-image: url("../assets//back_mobile.png");
    background-repeat: no-repeat;
    background-size: cover;
    -ms-flex-item-align: center;
        -ms-grid-row-align: center;
        align-self: center;
    right: 11vw;
    padding: 20px;
    top: 18vh;
    height: 70vw;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    border-radius: 15px;
    width: 80%;
    align-self: center;
    border-radius: 50px;
    padding: 10px 20px;
    border: 1px solid #ffffff;
    color: #ffffff;
    cursor: pointer;
    outline: none;
  }
  .headerContainer .mainContainer .rightSide h1 {
    font-weight: 600;
    font-size: 20px;
    width: 100%;
    padding-left: 15px;
  }
  .headerContainer .mainContainer .rightSide p {
    width: 100%;
    font-size: 15px;
    padding: 15px;
  }
}
