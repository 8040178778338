@import '../../styles//Global/Global.scss';

.navbarContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-color: white;
  height: 10vh;
  padding: 10px 20px;
  button {
    @include gradientButton(10%);
    background: linear-gradient(
      180deg,
      #b2f1ff 0%,
      rgba(252, 153, 255, 0.86) 100%
    );
  }
  a{
      width: 100%;
      text-decoration: none;
      color: white;
  }
}
@media screen and (max-width: 800px) {
    .navbarContainer{

        button{
            @include gradientButton(25%);
        }
        img{
            width: 100px;
            height: 40px;
            align-self: center;
        }
        .logo{
            width: 60px;
            height: 50px;
        }
        
    }
}