@import "../styles//Global/Global.scss";

.headerContainer {
  height: 100vh;

  .mainContainer {
    display: flex;
    flex-direction: row;
    .leftSide {
      width: 75vw;
      height: 90vh;
      // width: 50%;
      img {
        object-fit: cover;
        width: 100%;
        height: 100%;
      }
    }

    .rightSide {
      background-color: black;
      position: absolute;
      right: 0;
      background: linear-gradient(180deg, #9dd0f4 0%, #ccb2f8 100%);
      padding: 5%;
      height: 90vh;
      width: 40vw; //
      color: $white;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      h1 {
        font-weight: 600;
        font-size: 40px;
        width: 100%; //
      }
      p {
        width: 100%; //
        font-size: 25px;
      }
      .opacityp {
        opacity: 0.8;
        color: #5485b1;
      }
      button {
        @include gradientButton(50%);
        background: linear-gradient(
          180deg,
          #7fdafe 0%,
          rgba(89, 138, 233, 0.86) 100%
        );
      }
    }
  }
}

@media screen and (max-width: 1200px) {
  .headerContainer {
    .mainContainer {
      .leftSide {
        width: 95vw;
        height: 90vh;
      }
      .rightSide {
        h1 {
          font-size: 30px;
        }
      }
    }
  }
}

@media screen and (max-width: 800px) {
  .headerContainer {
    
    .mainContainer {
      background-image: url("../assets/mobile.png");
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
      // .leftSide {
      //   width: 95vw;
      //   height: 30vh;
      // }
      .leftSide {
        img {
          display: none;
        }
      }
      .rightSide {
        // display: none;
        background-image: url('../assets//back_mobile.png');
        background-repeat: no-repeat;
        background-size: cover;
        align-self: center;
        // height: 20vh;
        right: 11vw;
        padding: 20px;
     
        top:18vh;
        height: 70vw;
        justify-content: center;
        border-radius: 15px;
        // position: relative;
       
        h1 {
          font-weight: 600;
          font-size: 20px;
          width: 100%; //
          padding-left: 15px;
        }
        p {
          width: 100%; //
          font-size: 15px;
          padding: 15px;
        }
        @include gradientButton(80%);
      }
    }
  }
}
